export const FileSvg = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 28.001H7C6.73478 28.001 6.48043 27.8956 6.29289 27.7081C6.10536 27.5205 6 27.2662 6 27.001V5.00098C6 4.73576 6.10536 4.48141 6.29289 4.29387C6.48043 4.10633 6.73478 4.00098 7 4.00098H19L26 11.001V27.001C26 27.2662 25.8946 27.5205 25.7071 27.7081C25.5196 27.8956 25.2652 28.001 25 28.001Z"
      stroke="#5272BD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 4.00098V11.001H26"
      stroke="#5272BD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
