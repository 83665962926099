const ArrowReturnLoginIcon = () => {
  return (
    <svg
      width="21"
      height="10"
      viewBox="0 0 21 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 8.75L1.5 5M1.5 5L5.25 1.25M1.5 5H19.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowReturnLoginIcon;
