import { Layout } from 'core-ui';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Logo from './components/Logo';
import SideMenu from './components/SideMenu';
import { useEffect, useState } from 'react';
import MobileDrawer from './components/MobileDrawer';
import useResponsive from 'src/hooks/useResponsive';
import { AvatarIcon } from 'src/assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/redux/store';
import customerProfileSlice, {
  fetchCustomerProfile
} from 'src/redux/customer/profileSlice';
import { ICustomerProfile } from 'src/models/customer';

const CustomerLayout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [userName, setUserName] = useState('');
  const [userAvatar, setUserAvatar] = useState(AvatarIcon);
  const [siderWidth, setSiderWidth] = useState(220);
  const { customer } = useSelector(
    (state: RootState) => state[customerProfileSlice.name]
  );

  useEffect(() => {
    const customerData = customer as ICustomerProfile;
    if (customerData.name) {
      setUserName(customerData.name);
    }
    if (customerData.avatarUrl) {
      setUserAvatar(customerData.avatarUrl);
    }
  }, [customer]);

  useEffect(() => {
    dispatch(fetchCustomerProfile());
  }, []);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const { isMobile, windowWidth } = useResponsive();

  useEffect(() => {
    if (windowWidth < 1180) {
      setSiderWidth(180);
    } else {
      setSiderWidth(220);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (!isMobile) {
      setOpenDrawer(false);
    }
  }, [isMobile]);

  return (
    <Layout
      headerContent={
        <Header
          handleOpenDrawer={handleOpenDrawer}
          userName={userName}
          userAvatar={userAvatar}
        />
      }
      logoContent={<Logo />}
      defaultSiderWidth={siderWidth}
      hideCollapser
      contentStyle={{
        overflow: 'auto'
      }}
      headerStyle={{
        height: isMobile ? 64 : 56,
      }}
      customSider={<SideMenu />}
      siderClassName="max-md:hidden"
    >
      <MobileDrawer
        open={openDrawer}
        onClose={handleCloseDrawer}
        userName={userName}
        userAvatar={userAvatar}
      />
      <Outlet />
    </Layout>
  );
};

export default CustomerLayout;
