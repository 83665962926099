import Cookies from 'js-cookie';
import { createContext, ProviderProps, useEffect, useState } from 'react';
import { ACCESS_TOKEN_KEY } from 'src/constants/auth';
import { PermissionList } from 'src/enums/permission';
import { I_Profile } from 'src/models/admin';
import { PERMISSIONS } from 'src/pages/admin/Config/utils';
import { apiRequest } from 'src/utils/api';

interface I_Context {
  profile?: I_Profile;
  fetchProfile?: (
    shouldShowLoading?: boolean
  ) => Promise<I_Profile | undefined>;
  isProfileLoading: boolean;
  hasRole: (role: PermissionList) => boolean;
  hasAnyOfRoles: (roles: PermissionList[]) => boolean;
}

export const ProfileContext = createContext<I_Context>({
  isProfileLoading: true,
  hasRole: () => true,
  hasAnyOfRoles: () => true
});

const ProfileProvider = (props: Partial<ProviderProps<unknown>>) => {
  const [profile, setProfile] = useState<I_Profile>();
  const [isProfileLoading, setProfileLoading] = useState(true);
  const fetchProfile = async (shouldShowLoading?: boolean) => {
    if (!Cookies.get(ACCESS_TOKEN_KEY)) {
      return;
    }
    shouldShowLoading && setProfileLoading(true);
    const { data } = await apiRequest.get({
      url: '/api/admin'
    });
    setProfile(data.data);
    setProfileLoading(false);
    return data.data as I_Profile;
  };
  const hasRole = (role: PermissionList) =>
    Boolean(profile?.permissions.find((i) => i === role));
  const hasAnyOfRoles = (roles: PermissionList[]) =>
    Boolean(profile?.permissions.some((i) => roles.includes(i)));
  return (
    <ProfileContext.Provider
      {...props}
      value={{
        profile,
        fetchProfile,
        isProfileLoading,
        hasRole,
        hasAnyOfRoles
      }}
    />
  );
};
export default ProfileProvider;
